<template>
	<product-page :link="link" :name="name" :features="features" :video="video" :primary-color="color" :description="description" :image="image"></product-page>
</template>

<script>
import ProductPage from "../ProductPage";

export default {
	name: "BlokHost",
	components: {ProductPage},
	data() {
		return {
			name: "BlokHost",
			color: '#FF9120',
			link: "https://blok.host",
			image: "/images/product_pages/page_images/blok_host.svg",
			video: "/videos/blok_intro.mp4",
			description:
					"Blok.Host is disrupting the web hosting and file storage industry with blockchain technology. BlokHost solves key security and environmental issues that existing web2 providers can't.",
			features: [
				{
					name: "Hosted Forever",
					description: ["The ability to make hosted files with Blok.Host “immutable” means they cannot be edited or destroyed. Your files will be stored with our decentralised data storage providers forever! You can access and view them at any time. This is made possible through our fleet of community driven node operators."]
				},
				{
					name: "Web Security",
					description: [
						"A common web2 issue in the web hosting market is centralised web hosting providers being exploited by unwanted 3rd parties due to social engineering issues and a lack of security.", "Approximately $1.6billion worth of cryptocurrencies were stolen from DEFI protocols in the first quarter of 2022. Spearfishing and cure hijacking attacks have become common in the web3 space and hackers rely on exploiting unsecure DNS and hosting platforms in order to carry out these attacks. Web3 projects should not be using unsecure decentralised web hosting providers, Blok.Host and its immutable file storage mean even if an unwanted party was able to gain access to your websites front end, they would still not be able to do anything to change your code or put your web users at risk."],
				},
				{
					name: "Cost Effective",
					description: [
						"By utilising far cheaper decentralised file storage and our Blok node network we are able to create a web hosting product that is cheaper than most current web2 alternatives. We leverage the scalability of Solana and the shadow drive to create our own Blok node network, hosted content over time gets cheaper and cheaper to serve as our network grows and more nodes sign up to serve content.\n"
					]
				},
				{
					name: "Performance",
					description: [
						"Our decentralised operator network ensures we have nodes set up and serving content across the globe! You shall be served content requested from the most optimal geolocation for yourself individually. This results in a fast website load time. In an era whereby 90% of people will not view a site if it takes more than 4 seconds to load, we believe the speed of our network is of utmost importance.\n"
					]
				}
			]
		}
	}
}
</script>

<style scoped>

</style>