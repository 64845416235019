<template>
	<product-page :link="link" :name="name" :features="features" :video="video" :primary-color="color" :description="description" :image="image"></product-page>
</template>

<script>
import ProductPage from "../ProductPage";

export default {
	name: "Edit3D",
	components: {ProductPage},
	data() {
		return {
			name: "Edit3D",
			color: '#00CB79',
			link: "https://hourglassmeta.com/#/demo/editor",
			image: "/images/product_pages/page_images/edit3d.jpg",
			description: "Edit3D is disrupting the web hosting and file storage industry with blockchain technology.",
			features: [
				{
					name: "Ease of use",
					description:
							["Our user-friendly drag and drop metaverse creator is a tool that allows users to easily create and customise their own virtual environments without needing to have any knowledge of coding or other technical skills. The editor is a simple, intuitive interface that allows users to drag and drop pre-made objects, characters, and other elements into their virtual space, and then customise them using various options and controls. Examples of available options include; changing the appearance and behaviour of objects, as well as tools for adding lighting, sound effects, and other features to create a more immersive and engaging experience."]
				},
				{
					name: "Import any file",
					description:
							["Importing 2D or 3D files into our metaverse editor would typically involve using a specific file format that is supported by the editor. For example, common 3D file formats include GLB and GLTF, while common 2D file formats include PNG, JPG, and GIF. Once a file has been exported in one of these formats, it can be imported into the editor by using the appropriate import function. This may involve selecting the file on a user's computer and then using the editor's interface to specify how the file should be imported and placed within the virtual environment. In general, the process of importing 2D or 3D files into a metaverse editor should be relatively straightforward and user-friendly, provided that the user has the necessary file format.\n"]
				}
			]
		}
	}
}
</script>

<style scoped>

</style>