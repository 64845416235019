<template>
	<product-page :name="name" :features="features" :primary-color="color" :description="description" :image="image"></product-page>
</template>

<script>
import ProductPage from "../ProductPage";

export default {
	name: "Metaverse Engine",
	components: {ProductPage},
	data() {
		return {
			name: "Metaverse Engine",
			color: '#00CB79',
			image: "/images/product_pages/page_images/blok_host.svg",
			description: [
				"If you want to build a metaverse, currently you are usually presented with 2 options- either build in Unity or Unreal Engine. While both of these are fantastic engines, they are game engines and not developed specifically for the metaverse. These platforms may encounter issues of mass scale from a multitude of devices all over the world simultaneously.",
				"The AlphaBatem metaverse engine is built from the ground up to handle precisely this. Our unique engine pushes rendering to each user's device meaning no need for mass server farms to work around the clock rendering the virtual world. This approach is a lot more energy efficient and will allow many users from all over the world to access the metaverse from a variety of devices and all have a consistent user experience. Complex and large virtual worlds can be run from a web browser and even accessed via a smartphone. These will likely be standard engine requirements as the metaverse starts to gain adoption and mass scale."
			],
			features: [
				{
					name: "Mass Multiplayer",
					description: [
						"Our APIs can support mass multiplayer, something very few metaverses can currently deliver on. Thousands, tens of thousands, even millions of users accessing your virtual experience is possible with the AlphaBatem engine. \n"
					]
				},
				{
					name: "Web2 & Web3 Support",
					description: [
						"If you want your metaverse to run directly on-chain and be able to handle NFT transactions while in the metaverse that is no problem. Users can connect and manage their profiles through their crypto wallets. If you want a user to access via a QR code and want no cryptocurrency or NFT interactions, this also is not a problem! Whatever your preference, we are able to create a flawless metaverse experience without exception. It is an engine that has little limitations, whatever the vision you are trying to create. "
					]
				},
				{
					name: "Cost Effective",
					description: [
						"Our engine is designed to build and run a metaverse where the costs are kept as economically friendly as possible. Most current metaverse builder solutions rely on pixel streaming or mass data servers to render out virtual worlds. As users adopt and your world scales, there are huge costs to running your metaverse. In the early stages it is difficult to know how much revenue your metaverse or virtual experience will be able to generate. With our efficient rendering and streaming engine we can create a virtual world and environment that not only functions how you want, but also makes economic sense for your business. "
					]
				}
			]
		}
	}
}
</script>

<style scoped>

</style>