<template>
	<product-page :name="name" :features="features" :video="video" :primary-color="color" :description="description" :image="image"></product-page>
</template>

<script>
import ProductPage from "../ProductPage";
export default {
	name: "Alpha Advertiser",
	components: {ProductPage},
	data() {
		return {
			name: "Alpha Advertiser",
			color: '#00CB79',
			image: "/images/product_pages/page_images/blok_host.svg",
			video: "/images/lux_room_compressed.mp4",
			features: [
				{
					name: "Hosted Forever",
					description: ["1 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, animi consequuntur cupiditate deleniti, dolor eaque error eveniet inventore nobis, odit quam quidem quo reiciendis repellat reprehenderit sapiente sed ut voluptates?"]
				},
				{
					name: "Web Security",
					description: ["2 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, animi consequuntur cupiditate deleniti, dolor eaque error eveniet inventore nobis, odit quam quidem quo reiciendis repellat reprehenderit sapiente sed ut voluptates?"]
				},
				{
					name: "Cost Effective",
					description: ["3 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, animi consequuntur cupiditate deleniti, dolor eaque error eveniet inventore nobis, odit quam quidem quo reiciendis repellat reprehenderit sapiente sed ut voluptates?"]
				},
				{
					name: "Performance",
					description: ["4 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, animi consequuntur cupiditate deleniti, dolor eaque error eveniet inventore nobis, odit quam quidem quo reiciendis repellat reprehenderit sapiente sed ut voluptates?"]
				}
			]
		}
	}
}
</script>

<style scoped>

</style>