<template>
	<product-page :name="name" :features="features" :video="video" :primary-color="color" :description="description" :image="image"></product-page>
</template>

<script>
import ProductPage from "../ProductPage";

export default {
	name: "Hourglass",
	components: {ProductPage},
	data() {
		return {
			name: "Hourglass",
			color: '#00CB79',
			// image: "/images/product_pages/page_images/blok_host.svg",
			video: "/images/lux_room_compressed.mp4",
			description:
					[
						"The Hourglass metaverse is AlphaBatems working living proof of the capability of our custom engine and ability to create virtual worlds. We intend long term for this city to thrive with user activity. Hourglass also serves as our own personal playground, testing our engine and pushing the boundaries of what experiences are possible to create currently in a metaverse environment."
					],
			features: [
				{
					name: "The Map",
					description: [
						"The Hourglass Metaverse is a new world waiting to be explored, where each piece of virtual land is represented by a one-of-a-kind NFT. By owning a piece of the metaverse, you have the power to bring your wildest visions to life. Immerse yourself in game worlds beyond your imagination or create concert venues that leave a lasting impression, host virtual offices, build empires, or anything else you can dream up. The possibilities are endless for landowners in the Hourglass Metaverse.",
						"The current Hourglass Metaverse map is made up of 8486 NFT tiles of land represented by a runestone with its own unique lore and story.  Each runestone will also be a 3D GLB model itself and can be displayed in the metaverse with a simple drag and drop. "
					]
				},
				{
					name: "$TIME token",
					description: [
						"Our native $TIME token will power the metaverse and all transactions and events that occur within. 1 $TIME is created each minute and every 24 hours 1440 $TIME is available to be earned by metaverse creators and participants."
					]
				}
			]
		}
	}
}
</script>

<style scoped>

</style>