<template>
	<div class="products">
		<div class="container pt-5">

			<h1 class="mt-5 pt-5">Featured Products</h1>

			<Product class="my-5" v-for="(product, key) in products" :key="key" :product="product"></Product>

		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Product from "../components/products/Product";
import Footer from "../components/Footer";

export default {
	name: "Products",
	components: {Footer, Product},
	data() {
		return {
			products: [
				{
					name: "Blok Host",
					link: "/products/blok_host",
					demo: "https://admin.blok.host",
					// image: "/images/product_pages/page_images/blok_host.svg",
					video: "/videos/blok_intro.mp4",
					description: "Blok.Host is a decentralised web hosting platform developed by AlphaBatem Labs. Hosting a site currently takes less than 2 minutes and is cheaper and more efficient then current web2 hosting solutions. Unlike the web hosting titans of the industry, Blok.Host is also completely secure. With the ability to make all hosted files immutable any hacker or unwanted third party can never gain access to your front end site and edit anything keeping users safe."
				},
				{
					name: "LiquifyNFT",
					link: "/products/liquifynft",
					demo: "https://liquifynft.com",
					image: "/images/product_pages/product_images/liquifynft.png",
					description: "Blok.Host is a decentralised web hosting platform developed by AlphaBatem Labs. Hosting a site currently takes less than 2 minutes and is cheaper and more efficient then current web2 hosting solutions. Unlike the web hosting titans of the industry, Blok.Host is also completely secure. With the ability to make all hosted files immutable any hacker or unwanted third party can never gain access to your front end site and edit anything keeping users safe."
				},
				{
					name: "Edit3D",
					link: "/products/edit3d",
					demo: "/products/edit3d",
					image: "/images/product_pages/product_images/edit3d.jpg",
					description: "At AlphaBatem we believe in simplicity. Creating an interactive 3d virtual world with multiplayer functionality from scratch is currently a tedious and difficult task. We aim to simplify the entire process enabling anybody with access to a computer and the internet to build their own custom virtual world from scratch as a complete beginner!"
				},
				{
					name: "Hourglass Metaverse",
					link: "/products/hourglass",
					demo: "https://hourglassmeta.com",
					// image: "/images/product_pages/product_images/Hourglass-product holder.png",
					video: "/images/lux_room_compressed.mp4",
					description: "The Hourglass metaverse is AlphaBatems working living proof of the capability of our custom engine and ability to create virtual worlds. We intend long term for this city to thrive with user activity. Hourglass also serves as our own personal playground, testing our engine and pushing the boundaries of what experiences are possible to create currently in a metaverse environment."
				},
			]
		}
	}
}
</script>

<style scoped>

</style>