<template>
	<product-page  :link="link" :name="name" :features="features" :primary-color="color" :description="description" :image="image"></product-page>
</template>

<script>
import ProductPage from "../ProductPage";

export default {
	name: "AlphaWallet",
	components: {ProductPage},
	data() {
		return {
			name: "Alpha Wallet",
			description: "Open source solana wallet with a focus on simplicity & extensibility.",
			link: "https://github.com/alphabatem/alpha-wallet",
			color: '#00CB79',
			image: "/images/product_pages/page_images/wallet_overview.gif",
			features: [
				{
					name: "Open Source",
					description:
							["Open sourcing our blockchain wallet can provide several benefits in terms of user security. One of the main benefits is that open sourcing the code allows anyone to review it and audit it for potential security vulnerabilities. This can help to identify and address any issues before they are exploited by attackers.", "Additionally, open sourcing the code allows for the development of a wider community of users and developers who can contribute to improving the security of the wallet. This can help make the wallet more secure over time as more people are able to identify and fix potential security issues. Overall, open sourcing our blockchain wallet can help to improve user security by allowing for greater transparency, community involvement, and ongoing security improvements."]
				},
				{
					name: "Maximum Security",
					description: ["The Alpha wallet is fully encrypted with AES & SHA3, these are considered highly secure and published as the suggested cryptocurrency standard by most relevant authorities. The wallet also does not use external servers. Meaning your keys stay with you at all times and your activity while using the wallet cannot be monitored by anyone. "]
				},
				{
					name: "Scam NFT Detection",
					description: ["Protect your privacy and reduce the risk of interacting with scam NFT's through the Alpha Wallets protective NFT layer. NFT's suspected of being a scam or NFTs that are tracking wallets IP & activity will be restricted from being autoloaded when visiting your collections. This will allow users to quickly filter their favourite collections from untrustworthy and potentially harmful airdrops and collections. "]
				}
			]
		}
	}
}
</script>

<style scoped>

</style>