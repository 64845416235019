<template>
	<div class="product py-5">
		<div class="img-container text-center" v-if="product.image">
			<img :src="product.image" alt="" class="img-fluid">
		</div>
		<div class="video-container text-center" v-else-if="product.video">
			<video width="100%" autoplay muted playsinline loop>
				<source :src="product.video" type="video/mp4">
			</video>
			<slot></slot>
		</div>

		<h2 class="mt-5">{{ product.name }}</h2>

		<p class="mt-4">{{ product.description }}</p>

		<div class="row mt-5">
			<div class="col-2">
				<router-link class="btn btn-primary btn-block" :to="product.demo">Try Demo</router-link>
			</div>
			<div class="col-2">
				<router-link class="btn btn-outline-primary btn-block" :to="product.link">Learn More</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Product",
	props: {
		product: {
			type: Object,
			required: true
		}
	}
}
</script>

<style scoped>

</style>