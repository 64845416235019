<template>
	<div class="products">
		<div class="container">
			<div class="header mt-5">
				<div class="row">

					<div class="col-6">
						<h5>Products</h5>
						<h1 class="mt-4">{{ name }}</h1>

						<div v-if="typeof description === 'object'" class="description animate__animated animate__fadeIn">
							<p v-for="(d,k) in description" :key="k">{{ d }}</p>
						</div>
						<p v-else class="my-5 animate__animated animate__fadeIn">{{ description }}</p>

						<div class="row mt-5">
							<div class="col-6" v-if="link">
								<a :href="link" target="_blank" class="btn btn-block primary" :style="`background-color: ${primaryColor}; border-color: ${primaryColor}; color: black`">Get
									started</a>
							</div>
							<div class="col" v-if="video">
								<a href="#video" class="btn btn-outline-primary btn-block"><i class="fa fa-play"></i> <span class="ms-2">Watch Demo</span></a>
							</div>
						</div>
					</div>
					<div class="col-6 text-end">
						<img alt="" class="img-fluid" :src="image">
					</div>
				</div>
			</div>
			<section id="video" class="section my-5 py-5" v-if="video">
				<video width="100%" autoplay muted playsinline loop>
					<source :src="video" type="video/mp4">
				</video>
				<slot></slot>
			</section>
			<div class="features my-5">
				<h2>{{ name }} Features</h2>
				<div class="row my-5">
					<div class="col-3">
						<button @click="activeFeature = feature" class="btn btn-block mb-3"
								:class="feature.name === activeFeature.name ? '' : 'btn-outline-primary'"
								:style="feature.name === activeFeature.name ? `background-color: ${primaryColor}; border-color: ${primaryColor}; color: black` : ''"
								v-for="(feature,key) in features"
								:key="key">{{
								feature.name
							}}
						</button>
					</div>
					<div class="col offset-1 description">
						<p class="animate__animated animate__fadeIn" v-for="(d,k) in activeFeature.description" :key="k">{{ d }}</p>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from "../components/Footer";

export default {
	name: "ProductPage",
	components: {Footer},
	props: {
		name: {
			type: String,
			required: true,
		},
		description: {
			type: String || Array,
			required: true,
		},
		image: {
			type: String,
			required: true
		},
		video: {
			type: String,
			required: true
		},
		link: {
			type: String
		},
		features: {
			type: Array
		},
		primaryColor: {
			type: String,
			default: () => {
				return ''
			}
		}
	},
	data() {
		return {
			activeFeature: null
		}
	},
	beforeMount() {
		this.activeFeature = this.features[0]
	}
}
</script>

<style scoped>
.description {
	line-height: 2rem !important;
}

.features .description {
	min-height: 300px;
}

.btn.btn-block.primary:hover {
	background: white !important;
	border-color: white !important;
}

.btn.btn-outline-primary:hover span {
	color: black;
}
</style>