<template>
	<div class="products">
		<div class="container pt-5">


			<UseCasesSection></UseCasesSection>

		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Footer from "../components/Footer";
import UseCasesSection from "../components/home/UseCasesSection";

export default {
	name: "Industries",
	components: {UseCasesSection, Footer},
}
</script>

<style scoped>

</style>