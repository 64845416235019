<template>
	<product-page :name="name" :features="features" :link="link" :primary-color="color" :description="description" :image="image"></product-page>
</template>

<script>
import ProductPage from "../ProductPage";

export default {
	name: "LiquifyNFT",
	components: {ProductPage},
	data() {
		return {
			name: "LiquifyNFT",
			color: '#00CB79',
			link: "https://liquifynft.com",
			image: "/images/product_pages/page_images/liquify_sm.PNG",
			description:
					[
						"LiquifyNFT is a Solana based NFT dApp, we aggregate all available AMMs and liquidity pools allowing users to instantly swap any SOL NFT with any SPL token instantly.",
						"Our aggregator ensures users are always getting the best pricing available on the market."
					],
			features: [
				{
					name: "Trade In Any Token",
					description: [
						"Using LiquifyNFT, traders can buy or sell NFT's in any token and are no longer limited to using only SOL!",
						"We have partnered with JupiterAG to provide users the most optimal pricing for all tokens across all markets."
					]
				},
				{
					name: "Aggregates The Best Price",
					description: [
						"We integrate across all NFT markets & automated pools to bring you the best instant sale & purchase price."
					]
				},
				{
					name: "Multi Swap",
					description: [
						"Traders are now able to swap multiple Tokens & NFT's for other Tokens & NFT's within a single, easy to use interface!"
					]
				}
			]
		}
	}
}
</script>

<style scoped>

</style>